<template>
    <demographics @proceed='next' />
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core';
import { updateSubject, goToQuestionnaire } from '@/Study/helpers';
import Demographics from '@/Study/Pages/Demographics.vue';

export default defineComponent({
    components: { Demographics },
    computed: {
        study(){
            return this.$store.state.study!;
        },
        user(){
            return this.$store.state.user;
        }
    },
    methods: {
        async next(){
            const { dem } = this.study;
            const { sex } = this.$store.state.user;

            if(dem.button !== 'continue')
                return;

            dem.button = 'loading';

            try{
                const updates = { sex: sex ?? null, age: dem.age ?? null };
                updateSubject(updates);
                await goToQuestionnaire();
                dem.button = 'continue';
            }catch(error){
                console.error(error)
                dem.button = 'error';
            }
        }
    }
});
</script>